
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';

  import { Shipment } from '@/store/modules/Shipments/ShipmentsModule';

  export default defineComponent({
    name: 'shipments-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const { can } = useAbility();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const submitVideoButton = ref<HTMLButtonElement | null>(null);
      const loading = ref(false);
      const createdShipment = ref<Shipment | null>(null);
      const selectedOffice = ref<string>('');
      const selectStatus = ref<string>('');
      const videoInput = ref('');

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const shipmentsSchema = Yup.object().shape({
        officeId: Yup.string()
          .notOneOf([''], () => translate('OFFICE_IS_REQUIRED_FIELD'))
          .required(() => translate('OFFICE_IS_REQUIRED_FIELD'))
          .label('Office'),
      });

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        values.shipmentType = 'supplier';
        const result = await store.dispatch(Actions.CREATE_SHIPMENT, values);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          createdShipment.value = result;
          let id = result.id;
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_SHIPMENT'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'shipment-editing', params: { id } });
          });
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('shipmentCreating'), [
          translate('shipments'),
        ]);
        reinitializeComponents();
      });
      const Office = await store.dispatch(Actions.GET_SHIPMENT_GW_OFFICES);
      const offices = Office.data?.filter((office) => office.deletedat == null);
      await store.dispatch(Actions.GET_ALL_SHIPMENT_STATUSES, 'gw');
      // watch(
      //   () => selectedDriver.value,
      //   async () => {
      //     selectedLocation.value = 0;
      //     await store.dispatch(
      //       Actions.GET_ORGANIZER_LOCATIONS,
      //       selectedDriver.value
      //     );
      //   }
      // );

      return {
        submitVideoButton,
        onSubmitCreate,
        submitButton,
        translate,
        shipmentsSchema,
        goBack,
        can,
        loading,
        createdShipment,
        offices,
        // shipmentStatuses: computed(() => store.getters.shipmentStatusesList),
        selectedOffice,
        selectStatus,
        videoInput,
      };
    },
  });
